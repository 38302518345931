import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import FifthNews from '../../../News/FifthNews'
const SixContent = () => {
    return (
        <>
            <FifthNews />
        </>
    )
}

export default SixContent