import React from 'react'
import styles from './index.module.scss'

const NotFound = () => {
    return (
        <div className={styles['not-found']}>
            Not Found
        </div>
    )
}

export default NotFound