import { useEffect, useState } from 'react'
import styles from './index.module.scss'
import FourthNews from '../../../News/FourthNews'
const FifthContent = () => {
    return (
        <>
            <FourthNews />
        </>
    )
}

export default FifthContent